@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,800,400italic,700italic&subset=latin);
#root {
  height: 100%;
}

#root.dragging {
  cursor: move;
}

.field .field {
  width: 100%;
}

.inline.field {
  display: flex;
}

.ui.modal.transition.visible.active > .ui.table {
  margin: 0;
}

.ui.modal.transition.visible.active {
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
}

.modal-team-members-edit-form.ui.modal.transition.visible.active {
  min-width: 1300px;
}

td.collapsing.centered {
  text-align: center;
}

.ui.page.modals + .ui.page.modals.dimmer.transition.visible.active {
  justify-content: center;
}

.ui.page.modals + .tox + .ui.page.modals.dimmer.transition.visible.active {
  justify-content: center;
}

.ui.modal > .content.modal-form-content {
  padding: 0.5rem 1.5rem !important;
}

.ui.selection.dropdown {
  min-width: 7em;
}

td div.ui.input input {
  background: transparent;
  border: none;
  padding: 0;
  min-width: 5rem;
}

td div.ui.input input:focus {
  background: transparent;
}

td div.ui.selection.dropdown {
  background: transparent;
  border: none;
  padding: 0;
  min-height: inherit;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.ui.table tr:first-child td:focus-within,
.ui.celled.table tr td:focus-within,
.ui.table tr td:focus-within {
  border: 1px solid #85b7d9;
  border-top: 1px solid #85b7d9 !important;
}

.ui.table tr:first-child td.negative:focus-within,
.ui.celled.table tr td.negative:focus-within,
.ui.table tr td.negative:focus-within {
  border: 1px solid #be635f;
  border-top: 1px solid #be635f !important;
}

.ui.modal > .content {
  line-height: 1;
}

form:first-child(.ui.horizontal.divider) {
  margin-top: 0;
}

.ui.table {
  border-collapse: collapse;
}

.ui.table thead th {
  border-bottom: none;
}

.ui.table tfoot {
  border-top: 1px solid rgba(179, 181, 183, 0.26);
}

.ui.header {
  margin-top: 0.3em;
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.ui.accordion .accordion {
  margin-top: 0;
}

.ui.styled.accordion .content.active > .accordion {
  box-shadow: none;
}

.ui.styled.accordion .content.active > .accordion .title {
  border-top: none;
}

.ui.styled.accordion .accordion .content,
.ui.styled.accordion .content {
  padding: 0 1em 0.8em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
}

@media only screen and (min-width: 768px) {
  .modal-new-timesheet-entry.ui.modal,
  .ui.modal.modal-invoice-create-form,
  .ui.modal.modal-proposal-create-form,
  .ui.modal.modal-to-be-invoiced-projects-add-invoice {
    width: 30%;
  }

  .ui.modal.modal-add-project-item-to-project,
  .ui.modal.modal-add-project-item-to-proposal {
    width: 60%;
  }
}

input[id^='rc-editable-input'] {
  height: 20px !important;
}

@media only screen and (min-width: 992px) {
  .ui.modal.holiday-schedule,
  .ui.modal-holiday-schedule-edit {
    width: 80%;
    margin: 0;
  }
}

.ui.modal.holiday-schedule > .content,
.ui.modal.holiday-schedule-edit > .content {
  padding: 0.5em;
}

td div.ui.input input {
  width: 100%;
}

.ui.search.selection.dropdown > input.search {
  line-height: 1.21428571em;
  padding: 0.77857143em 1.5em 0.67857143em 0.5em;
}

.ui.dimmer {
  background-color: rgba(52, 52, 52, 0.51);
}

.ui.segment.inverted > .ui.dimmer {
  background-color: rgba(242, 236, 236, 0.2);
}

.react-draggable-dragging {
  cursor: move;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ .coloring.black:before,
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ .coloring.black:before,
.ui.toggle.checkbox input:checked ~ .box::before,
.ui.toggle.checkbox input:checked ~ label::before,
.ui.toggle.checkbox input:focus:checked ~ .box::before,
.ui.toggle.checkbox input:focus:checked ~ label::before {
  background: #00a650 !important;
}

.dark-theme-toggle.ui.toggle.checkbox input:checked ~ .box,
.dark-theme-toggle.ui.toggle.checkbox input:checked ~ label {
  color: #ffffff !important;
}

.ui.styled.accordion.inverted,
.ui.styled.accordion.inverted .accordion {
  background-color: inherit;
}

.ui.styled.accordion.inverted .accordion .active.title,
.ui.styled.accordion.inverted .accordion .title:hover {
  color: white;
}

.ui.inverted.table .ui.input input {
  color: #ffffff !important;
}

.ui.inverted.table .ui.selection > .text {
  color: #ffffff !important;
}

.ui.table.inverted .ui.table td.positive,
.ui.table.inverted tr.positive {
  background: #2c662d42 !important;
  color: #fff !important;
}

.ui.table.inverted .ui.table td.warning,
.ui.table.inverted tr.warning {
  background: #573a08 !important;
  color: #fff !important;
}

.ui.table.inverted .ui.table td.negative,
.ui.table.inverted tr.negative {
  background: #9f3a3887 !important;
  color: #fff !important;
}

.ui.inverted.table tbody tr.negative td.selectable:hover,
.ui.selectable.inverted.table tbody tr.negative:hover {
  background: #9f3a385c !important;
}

.ui.inverted.table tbody tr.warning td.selectable:hover,
.ui.selectable.inverted.table tbody tr.warning:hover {
  background: #573a085c !important;
}

.ui.inverted.table tbody tr.positive td.selectable:hover,
.ui.selectable.inverted.table tbody tr.positive:hover {
  background: #2c662d5c !important;
}

.ui.basic.button#project-time-spent-select-period-button {
  box-shadow: none !important;
}

#invoice-edit-main-fields,
#proposal-edit-main-fields {
  padding-bottom: 0.2em;
}

.ui.form#projects-edit-form,
#invoice-edit-main-fields,
#proposal-edit-main-fields {
  border-radius: 7px;
  margin-bottom: 15px;
  background: #e9e9e9;
}

.ui.form.inverted#projects-edit-form,
.ui.form.inverted #invoice-edit-main-fields,
.ui.form.inverted #proposal-edit-main-fields,
.ui.form.inverted#big-chiefs-edit-form,
.ui.form.inverted#top-dogs-edit-form {
  background: #323232;
}

#big-chiefs-edit-form,
#top-dogs-edit-form {
  border-radius: 7px;
}

.ui.loading.form::before {
  background: rgba(255, 255, 255, 0.1);
}

.modal-add-project-item-to-proposal {
  max-width: 500px;
}

.ui.table.inverted td.negative {
  background: #9f3a3887 !important;
}

.ui.selectable.table.inverted tr:hover td.negative {
  background: #9f3a385c !important;
}

@media only screen and (min-width: 1200px) {
  .ui.modal.user-profile {
    width: 700px;
  }
}

.tasks-pool-container,
.projects-pool-container {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .tasks-pool-container,
  .projects-pool-container {
    width: 49.7%;
  }
}

.combined-chart-container {
  height: 420px;
}

@media only screen and (min-width: 2455px) {
  .combined-chart-container {
    height: 450px;
  }
}

@media only screen and (min-width: 600px) {
  .tasks-pool-container,
  .projects-pool-container {
    width: 49.7%;
  }
}

@media only screen and (min-width: 1200px) {
  .ui.modal.mini-profile {
    width: 600px;
  }
}

@media only screen and (min-width: 1920px) {
  .ui.modal.mini-profile {
    width: 600px;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

